// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import Select from 'react-select';

import ClaimTable from '../subComponents/ClaimTable';

import { fetchClaims, fetchClaimsCount } from '../../helpers/dataHandler';

import api from "../../api";

import '../../styles/claims.css';

export default class Claims extends React.Component {

    state = {
        pendingClaims: [],
        claims: [],
        currentTimeScale: 3,
    }

    claimInterval = null;

    componentDidMount = async () => {
        this.props.setLoadingState(true);

        await api.post("self/adminLogin").catch(() => {
            this.props.history.push("/login");
            return
        })

        // get total claims count
        const pendingClaimsCount = await fetchClaimsCount({status: 'Pending'});
        const claimsCount = await fetchClaimsCount();

        this.setState({
            claimsCount: claimsCount ? claimsCount : 0,
            pendingClaimsCount: pendingClaimsCount ? pendingClaimsCount : 0
        });

        await this.fetchClaims();

        this.fetchInterval = setInterval(this.fetchClaims, this.props.refreshInterval ? this.props.refreshInterval : 60000);
        this.props.setLoadingState(false);
    }

    componentWillUnmount = async () => {
        // unset specific employee claims filter
        if (this.props.employeeIdForClaims && this.props.employeeIdForClaims.length > 0)
            this.props.setEmployeeIdForClaims();

        if (this.claimInterval)
            clearInterval(this.claimInterval)
    }

    fetchClaims = async () => {

        let query = {};
        let pendingQuery = {status: 'Pending'};

        // get time offset date
        if (this.state.currentTimeScale > 0) {
            let dateAfter = new Date();
            dateAfter.setMonth(dateAfter.getMonth() - this.state.currentTimeScale);
            query.createdDate = { $gte: dateAfter }
            pendingQuery.createdDate = { $gte: dateAfter }
        };

        const claims = await fetchClaims(query);
        const pendingClaims = await fetchClaims(pendingQuery);
        await this.setState({
            claims: claims,
            pendingClaims: pendingClaims
        });
    }

    setNewTimeScale = async newTimeScale => {
        this.props.setLoadingState(true);
        await this.setState({ currentTimeScale: newTimeScale });
        await this.fetchClaims();
        this.props.setLoadingState(false);
    }

    render = () => {
        return (
            <div>
                <h3>Claims</h3>

                <ClaimTable
                    title='All Claims'
                    data={this.state.claims}
                    operators={this.props.operators}
                    history={this.props.history}
                    employeeIdForClaims={this.props.employeeIdForClaims}
                    reloadData={this.fetchClaims}
                    reloadOperators={this.props.reloadOperators}
                    currentTimeScale={this.state.currentTimeScale}
                    setNewTimeScale={this.setNewTimeScale}
                    setLoadingState={this.props.setLoadingState}
                />

            </div>
        )
    }

};