// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import ReactLoading from "react-loading";
import Select from 'react-select';

import "../../styles/manageEmployer.css";

import api from "../../api";

import EmployeeTable from '../subComponents/EmployeeTable';
import PlanCard from '../subComponents/PlanCard';
import Checkbox from '../subComponents/Checkbox';

import {fetchEmployerById, fetchEmployees, fetchClaims, updateEmployer, sendEmailToHR, removePlan, createPlan, createClaim} from '../../helpers/dataHandler';
import {promptForDelete, getLoadingGraphic} from '../../helpers/helperFunctions';

export default class ManageEmployer extends React.Component {

    state = {
        loading: true,

        employerId: null,
        employer: null,
        employees: [],

        editsMade: false,
        editingFields: {
            employerName: '',
            plans: [],
        },

        plans: [],

        emailSendingStatus: {
            sending: [],
            sent: []
        },

        currentPlanYear: 'All',

        creatingNewClaim: false,
        newClaimEmployeeId: null,
        newClaimPlanIdx: -1,

        choosingEmails: false,
        selectedPlans: [],
        sendFunction: null,
    }

    fetchInterval = null;

    componentDidMount = async () => {
        this.props.setLoadingState(true);
        this.setState({ loading: true })

        await api.post("self/adminLogin").catch(() => {
            this.props.history.push("/login");
            return
        })

        // get employerId from path
        const pathSplit = this.props.location.pathname.split('/');
        const employerId = pathSplit[pathSplit.length - 1]

        await this.setState({
            employerId: employerId,
            editable: this.props.location.state && this.props.location.state.createNew && this.props.location.state.createNew === true ? true : false,
        })

        await this.fetchAllData();

        this.props.setLoadingState(false);

        this.fetchInterval = setInterval(this.fetchAllData, this.props.refreshInterval ? this.props.refreshInterval : 60000);
        await this.resetEditingFields();
        this.setState({ loading: false })
    }

    componentWillUnmount = async () => {
        if (this.fetchInterval)
            clearInterval(this.fetchInterval)
    }

    componentDidUpdate = async prevProps => {
        // if (this.state.editable && !this.props.location.state.createNew) {
        //     this.setState({editable: false})
        // }
    }

    fetchAllData = async () => {
        await this.fetchEmployer();
        await this.fetchPlans();
        await this.fetchEmployees();
    }

    fetchPlans = async () => {
        let lastPlans = [...this.state.editingFields.plans];
        let plans = await api.get("employer/getPlans", {id: this.state.employerId})

        for (let i=0; i<lastPlans.length; i++) {
            lastPlans[i].graphic = plans[i].graphic
        }

        for (let i=lastPlans.length; i<plans.length; i++) {
            lastPlans.push(plans[i]);
        }

        await this.setState({
            plans,
            editingFields: {
                ...this.state.editingFields,
                plans: lastPlans,
            }
        })
    }

    fetchEmployer = async () => {
        const employer = await fetchEmployerById(this.state.employerId)
        await this.setState({
            employer: employer,
            employerId: this.state.employerId,
        });
    }

    fetchEmployees = async () => {
        // let employees = await fetchEmployees({employer: this.state.employerId})
        let employees = [];
        for (let plan of this.state.plans) {
            let employeesWithPlans = await fetchEmployees( {plans: plan._id})
            employeesWithPlans = employeesWithPlans.filter(emp => !employees.map(x => x._id).includes(emp._id));

            for (let emp of employeesWithPlans)
                employees.push(emp);
        }

        for (let i=0; i<employees.length; i++) {
            const claims = await fetchClaims({ employee: employees[i]._id });
            employees[i].numClaims = claims.filter(clm => clm.employer === this.state.employerId).length;
            employees[i].status = employees[i].flagged && employees[i].flaggedEmailDates && employees[i].flaggedEmailDates.length >= 5
                ? 'Unresponsive' : employees[i].flagged
                ? 'Flagged'
                : claims.filter(clm => clm.status === 'Pending').length > 0
                ? 'Pending' : claims.length > 0 ? 'Complete' : 'None';
        }
        await this.setState({ employees: employees });
    }

    addNewPlan = async () => {
        await this.setState({currentPlanYear: 'All'});
        await createPlan({object: {
            employer: this.state.employer._id,
            name: '',
            explanation: '',
        }});
        let lastPlans = [...this.state.editingFields.plans]
        await this.fetchPlans()
        this.setState({editingFields: {
            ...this.state.editingFields,
            plans: [
                ...lastPlans,
                this.state.plans[lastPlans.length]
            ]
        } })
        // this.toggleEditing();
        this.refs['plan_' + String(this.state.plans.length - 1)].scrollIntoView();
    }

    toggleEditing = async (on) => {
        await this.setState({editsMade: false})
        await this.resetEditingFields();
    }

    resetEditingFields = async () => {
        if (!this.state.employer)
            return;

        await this.setState({
            editingFields: {
                employerName: this.state.employer.name,
                plans: [...this.state.plans],
            },
        });
    }

    saveEdits = async () => {
        this.props.setLoadingState(true);

        // save employer
        await updateEmployer(this.state.employer._id, {name: this.state.editingFields.employerName})

        // save plans
        for (let plan of this.state.editingFields.plans) {
            await api.post("plan/update", {id : plan._id, diff: plan})
        }
        await this.fetchEmployer();
        await this.fetchPlans();
        await this.toggleEditing();

        if (this.props.location.state && this.props.location.state.createNew) {
            this.props.history.push("/employer/" + this.state.employerId)
        }
        this.props.setLoadingState(false);

    }

    setEmployerName = (newName) => {
        if (!this.state.editsMade)
            this.setState({editsMade: true});

        let fields = {...this.state.editingFields}
        fields.employerName = newName;
        this.setState({editingFields: fields});
    }

    setPlanValue = async (planId, fieldName, newValue) => {
        if (!this.state.editsMade)
        this.setState({editsMade: true});

        let plans = JSON.parse(JSON.stringify(this.state.editingFields.plans));

        let planIdx = plans.map((p, idx) => { return {match: p._id === planId, idx}})

        planIdx = planIdx.filter(x => x.match === true)[0].idx;
        plans[planIdx][fieldName] = newValue;

        this.setState({
            editingFields: {
                ...this.state.editingFields,
                plans
            }
        });
    }

    removeEmployerEmail = async (email) => {
        await updateEmployer(this.state.employer._id, {
             $pull: { emails: email }
        });
        this.fetchEmployer();
    }

    sendHrEmail = async (email, emailIdx) => {
        console.log(email, emailIdx)
        this.props.setLoadingState(true);
        this.setState({
            emailSendingStatus: {
                ...this.state.emailSendingStatus,
                sending: [
                    ...this.state.emailSendingStatus.sending,
                    emailIdx,
                ]
            },
            choosingEmails: false,
            selectedPlans: [],
        });

        // Determine all the plans to send by plan name
        let plansToSend = this.state.plans.filter(plan => this.state.selectedPlans.includes(plan.name))

        await sendEmailToHR(email.email, plansToSend.map(plan => {return {name: plan.name, code: plan.code}; }));
        this.setState({
            emailSendingStatus: {
                sending: this.state.emailSendingStatus.sending.filter(x => x !== emailIdx),
                sent: [
                    ...this.state.emailSendingStatus.sent,
                    emailIdx,
                ]
            }
        });

        this.props.setLoadingState(false);
    }

    addNewHrEmail = async () => {
        const newName = prompt('Enter the name of the HR contact.');
        if (!newName)
            return;

        const newEmail = prompt('Enter the new HR email address.');
        if (!newEmail)
            return;

        if (newEmail) {
            await updateEmployer(this.state.employer._id, {
                $push: { emails: {
                    name: newName,
                    email: newEmail.toLowerCase(),
                }}
            });
            this.fetchEmployer();
        }
    }

    removePlan = async (id) => {
        if (promptForDelete(this.state.plans.filter(p => p._id === id)[0].code)) {
            this.props.setLoadingState(true);
            await removePlan(id);

            // remove plan and graphics from state
            await this.setState({
                editingFields: {
                    ...this.state.editingFields,
                    plans: this.state.editingFields.plans.filter(p => p._id !== id),
                }
            })

            await this.fetchPlans();
            this.props.setLoadingState(false);
        }
    }

    setNewPlanYear = year => {
        this.setState({currentPlanYear: year})
    }

    getPlanYearOptions = () => {
        let options = this.state.plans ? this.state.plans
            .map(p => p.year)
            .filter((item, idx, arr) => arr.indexOf(item) === idx)
            .map(year => {return {label: year, value: year}}) : []

        let result = [{label: 'All', value: 'All'}];
        options.forEach(o => result.push(o));
        return result;
    }

    setHrEmailAutoSend = async (email, checked) => {
        console.log('checked')
        let query = {};
        if (checked === true)
            query.$push = { emailSendList: email.email }
        else
            query.$pull = { emailSendList: email.email }

        await api.post('employer/update', {
            id: this.state.employer._id,
            diff: query,
        });

        this.fetchEmployer();
    }

    createNewClaim = async () => {
        this.setState({creatingNewClaim: false})
        this.props.setLoadingState(true);
        const employee = this.state.employees.filter(x => x._id === this.state.newClaimEmployeeId)[0];
        const plan = this.state.plans[this.state.newClaimPlanIdx];

        const object = {
            employee: employee._id,
            plan: plan._id,
        }

        try {
            let claimResult = await createClaim({object})
            let claim = claimResult.claim;
            this.props.history.push('/claim/' + claim._id)
        } catch (err) {
            window.alert('An error occurred while creating the new claim.')
        }

        this.props.setLoadingState(false);
        this.setState({ newClaimEmployeeId: null, newClaimPlanIdx: -1})
    }

    render = () => {

        const employerName = this.state.editingFields.employerName && this.state.editingFields.employerName.length > 0 ? this.state.editingFields.employerName : (this.state.employer && this.state.employer.name ? this.state.employer.name : '');

        const allPlans = this.state.editsMade && this.state.editingFields.plans.length > 0 ? [...this.state.editingFields.plans] : [...this.state.plans];

        return (<>

            {this.state.choosingEmails !== false && <div className="choosingEmailsContainer">
                <div className='choosingEmailsBox'>
                    <h2 style={{textAlign: 'center'}}>{"Select which plan codes to send to " + this.state.choosingEmails}</h2>

                    <div
                        style={{ left: '20%', position: 'relative', marginBottom: '5px', maxHeight: '30vh', overflowY: 'auto' }}
                    >

                        {this.state.plans.map(plan => {
                            return (
                                <div>
                                    <div className='choosingEmailsInput'>
                                        <Checkbox
                                            checked={this.state.selectedPlans.includes(plan.name)}
                                            onClick={async () => {
                                                if (this.state.selectedPlans.includes(plan.name))
                                                    await this.setState({ selectedPlans: this.state.selectedPlans.filter(x => x !== plan.name) })
                                                else {
                                                    let selectedPlans = [...this.state.selectedPlans];
                                                    selectedPlans.push(plan.name)
                                                    await this.setState({ selectedPlans })
                                                }
                                                console.log(this.state.selectedPlans)
                                            }}
                                        />
                                    </div>
                                    <span>{plan.name}</span>
                                </div>
                            )
                        })}
                    </div>

                    <button
                        style={{ position: 'absolute', bottom: '3%', left: '5%', width: '30%' }}
                        className="viewDownloadBtn claimBtn"
                        onClick={() => this.setState({choosingEmails: false, selectedPlans: []})}
                    >Cancel
                    </button>

                    {this.state.selectedPlans.length > 0 && <button
                        className="sendReceiptBtn claimBtn"
                        style={{position: 'absolute', bottom: '3%', right: '5%', width: '30%'}}
                        onClick={async () => this.state.sendFunction()}
                    >Send
                    </button>}
                </div>
            </div>}

            <h3>Manage Employer</h3>

            <div className="manageEmployerContainer">
                <div className="edit-page-btns">
                    {this.state.editsMade &&
                        <button
                            className="edit-save-button"
                            onClick={async () => await this.toggleEditing()}
                        >
                            DISCARD
                        </button>
                    }

                    {this.state.editsMade && <button
                        className="edit-page-button"
                        onClick={() => this.saveEdits()}
                    >
                        SAVE PAGE
                    </button>}
                </div>

                <div className="employer-name">

                    <h2>Employer Name</h2>
                    <input
                        type="text"
                        id=""
                        value={employerName}
                        onChange={evt => this.setEmployerName(evt.target.value)}
                    />
                </div>

                <div className="hrContact">
                    <h2 style={{ display: 'inline-block' }}>Human Resources Contact</h2>
                    <div className='addNewEmployerEmail clickable' onClick={this.addNewHrEmail}>Add New +</div>

                    {this.state.employer && this.state.employer.emails.length > 0 ? this.state.employer.emails.map((email, emailIdx) => {
                        return (
                            <div className='hrContactEmailElement' key={email.email}>
                                <div
                                    className='hrContact_SendEmail clickable'
                                    onClick={() => this.setState({choosingEmails: email, sendFunction: this.sendHrEmail.bind(null, email, emailIdx)})}
                                >
                                    {this.state.emailSendingStatus.sent.includes(emailIdx)
                                        ? 'Resend Email' : this.state.emailSendingStatus.sending.includes(emailIdx)
                                            ? <div style={{ position: 'relative', left: 'calc(50% - 10px)' }}>{getLoadingGraphic('20px','20px')}</div>
                                        : 'Send Email'}
                                </div>

                                <div className='hrCheckbox'>
                                    <Checkbox
                                        onClick={e => this.setHrEmailAutoSend(email, e.target.checked)}
                                        checked={this.state.employer.emailSendList.includes(email.email)}
                                    />
                                </div>

                                <img className='btnHrContactRemoveEmail clickable' alt="remove" src='/icons/close.svg' onClick={async () => {
                                    if (window.confirm('Are you sure you want to delete ' + email.name + '?')) {
                                        this.props.setLoadingState(true);
                                        await this.setHrEmailAutoSend(email, false)
                                        await this.removeEmployerEmail(email);
                                        this.props.setLoadingState(false);
                                    }
                                }} title='Remove HR email'/>

                                {email.name}

                            </div>
                        )
                    }) : ''}
                </div>

                <div className="employee-table-container">
                    <EmployeeTable
                        history={this.props.history}
                        data={this.state.employees}
                        setEmployeeIdForClaims={this.props.setEmployeeIdForClaims}
                        reloadData={this.fetchAllData}
                        setLoadingState={this.props.setLoadingState}
                        createNewClaim={id => this.setState({creatingNewClaim: true, newClaimEmployeeId: id})}
                    />
                </div>

                {this.state.creatingNewClaim && <div className='addNewClaimContainer'>
                    <div className='addNewClaimBox'>

                        <h2 style={{ textAlign: 'center' }}>Select a plan for the new claim</h2>


                        <div
                            style={{ left: '20%', width: '60%', position: 'relative', marginBottom: '5px', maxHeight: '30vh', overflowY: 'auto' }}
                        >
                            {this.state.plans.map((plan, planIdx) => {
                                return (
                                    <div
                                        onClick={() => this.setState({newClaimPlanIdx: planIdx})}
                                        style={{padding: '5px', textAlign: 'center', cursor: "pointer", backgroundColor: this.state.newClaimPlanIdx === planIdx ? 'rgb(0, 117, 175)' : planIdx%2 === 0 ? 'white' : 'rgba(0,0,0,0.15)', color: this.state.newClaimPlanIdx === planIdx ? "rgb(255, 255, 255)" : "rgb(10, 10, 10)"}}
                                    >
                                        <div>{plan.name}</div>
                                        <div>{plan.year + ' (code: ' + plan.code + ')'}</div>
                                    </div>
                                )
                            })}
                        </div>

                        <button
                            style={{ position: 'absolute', bottom: '3vh', left: '5vw', width: '30%' }}
                            className="viewDownloadBtn claimBtn"
                            onClick={() => this.setState({ creatingNewClaim: false, newClaimEmployeeId: null, newClaimPlanIdx: -1 })}
                        >Cancel
                        </button>

                        {this.state.newClaimPlanIdx !== -1 && <button
                            className="sendReceiptBtn claimBtn"
                            style={{ position: 'absolute', bottom: '3vh', right: '5vw', width: '30%' }}
                            onClick={async () => this.createNewClaim()}
                        >Create Claim
                        </button>}
                    </div>
                </div>}

            </div>

            <div className="card-table-container">

                    <div className = "plans-header">
                        <h2 style={{ display: 'inline-block' }}>Plans</h2>
                        <div className='addNewPlan clickable' onClick={ async () => {await this.addNewPlan(); this.setState({editsMade: true})}}>Add New +</div>
                    </div>


                    {(this.state.plans && this.state.plans.length > 0) && <Select
                        key={'planYearSelect'}
                        className='planYearSelect_container'
                        classNamePrefix='planYearSelect'
                        value={this.getPlanYearOptions().filter(opt => opt.value === this.state.currentPlanYear)}
                        options={this.getPlanYearOptions()}
                        onChange={async newPlanYear => this.setNewPlanYear(newPlanYear.value)}
                    />}

                    {allPlans.length > 0
                        ? allPlans.filter(p => this.state.currentPlanYear === 'All' || p.year === this.state.currentPlanYear)
                            .map((plan, planIdx) => {
                                return (
                                    <div className="plan-card" ref={'plan_' + planIdx} key={planIdx}>
                                        <PlanCard
                                            plan={plan}
                                            removePlan={this.removePlan}
                                            setPlanValue={this.setPlanValue}
                                            fetchPlans={this.fetchPlans}
                                        />
                                    </div>
                                )
                    }) : ''}
            </div>
        </>)
    }
};