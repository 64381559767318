// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import axios from 'axios';

axios.defaults.withCredentials = true;

// Set API URL based on environment
// const apiUrl =  window.location.href.includes('staging') ? "https://api-staging.myfbcapp.com/" : window.location.href.includes('myfbcapp') ? "https://api.myfbcapp.com/" : "http://localhost:8080/";
// const apiUrl = "http://localhost:8080/"
const apiUrl = "https://api.myfbcapp.com/"; // Production
// const apiUrl = "https://api-staging.myfbcapp.com/"; // Staging

export default {

    apiUrl, // Return active API URL

    // Get request
    get: async (endpoint, data = {}, noAuth = false, responseType) => {
        try {
            let res = await axios.get(apiUrl + endpoint, { responseType, params: data });

            if (res.data && res.data.error) {
              console.log(res.data.error);
              throw new Error(res.data.error);
            }

            return res.data;
          } catch (error) {
            console.error("API request failed:", error);
            throw error;
          }
    },

    // Post request
    post: async (endpoint, data = {}, noAuth = false, config = {}, formData = false) => {

        let res = await axios.post(apiUrl + endpoint, data, config);

        // // Authorization error - redirect to login page
        // if (!res.data.auth && !noAuth)
        //     return window.location = '/login';

        if (res.data && res.data.error)
            console.log(res.data.error);

        return res.data;

    }

}