import React, { Component } from 'react';

import api from '../../api';

import { promptForDelete, getLoadingGraphic } from '../../helpers/helperFunctions';
import { fetchPlanGraphic } from '../../helpers/dataHandler';

import '../../styles/planCard.css';

export default class PlanCard extends Component {
    state = {
        loadingGraphic: true,
        graphic: null,
    }

    componentDidMount = async () => {
        // File uploader for receipt
        this.graphicUpload = document.createElement('input');
        this.graphicUpload.setAttribute('type', 'file');

        // get graphic
        this.fetchPlanGraphic();
    }

    componentDidUpdate = async (prevProps) => {
        // if (JSON.stringify(this.props.plan.graphic) !== prevProps.plan.graphic) {
        if (this.props.plan.graphic !== prevProps.plan.graphic) {
            console.log('update')
            this.fetchPlanGraphic();
        }
    }

    fetchPlanGraphic = async () => {
        try {
            if (this.props.plan.graphic) {
                this.setState({loadingGraphic: true});
                console.log(this.props.plan)
                const graphicBlob = await fetchPlanGraphic(this.props.plan._id, this.props.plan.graphic);
                this.setState({graphic: URL.createObjectURL(graphicBlob), loadingGraphic: false});
            } else
                this.setState({loadingGraphic: false, graphic: null});
        } catch (err) {
            this.setState({loadingGraphic: false});
        }
    }

    uploadPlanGraphic = async (planId) => {
        // store current changes
        const currentPlan = {
            name: this.props.plan.name,
            year: this.props.plan.year,
            explanation: this.props.plan.explanation,
        }

        this.graphicUpload.click();
        this.graphicUpload.onchange = async e => {
            this.setState({loadingGraphic: true})
            let formData = new FormData();
            formData.set("id", planId);
            let file = e.composedPath?.()?.[0]?.files?.[0] || e.path?.[0]?.files?.[0] || e.target.files[0];
            formData.append('files', file);
            formData.forEach((value, key) => {
                console.log(`${key}:`, value);
            });
            await api.post("plan/uploadGraphic", formData, false, {}, true);
            await this.props.fetchPlans();
            await this.fetchPlanGraphic();

            // reset plan changes
            const keys = Object.keys(currentPlan);
            for (let key of keys) {
                this.props.setPlanValue(this.props.plan._id, key, currentPlan[key])
            }
        }
    }

    getDescriptionStyle = () => {
        let style = { width: "92.5%", height: "120px", resize: "none" }
        if (this.props.locked)
            style.outline = 'none'
        return style;
    }

    getInputStyle = () => {
        let style = { width: '92.5%' }
        if (this.props.locked)
            style.border = 'none';
    }

    render = () => {
        return (
            <div className="plan-card">
                <div style={{ display: "inline-block" }}>

                    <h4>Plan Code: <span>{this.props.plan.code}</span></h4>

                    {!this.props.locked && <div
                        className="upload-btn-container clickableUploadButton"
                        onClick={async () => this.uploadPlanGraphic(this.props.plan._id)}
                    >
                        Upload graphic
                        <img
                            className='upload-btn'
                            title='Upload File'
                            src="/icons/upload.svg"
                            alt="upload icon"
                        />
                    </div>}

                    {!this.props.locked && <img
                        className="close-btn clickable"
                        title='Remove Plan'
                        src="/icons/close.svg"
                        alt="close icon"
                        onClick={async () => this.props.removePlan(this.props.plan._id)}
                    />}

                    <div className="plan-card-inputbox">
                        <label>Title:</label>

                        <input
                            className={this.props.locked ? 'planCardInput_noHover' : ''}
                            readOnly={this.props.locked}
                            style={this.getInputStyle()}
                            type="text"
                            value={this.props.plan.name}
                            onChange={e => {
                                if(!this.props.locked)
                                    this.props.setPlanValue(this.props.plan._id, 'name', e.target.value);
                            }}
                        />

                        <label>Year:</label>

                        <input
                            className={this.props.locked ? 'planCardInput_noHover' : ''}
                            readOnly={this.props.locked}
                            style={this.getInputStyle()}
                            type="text"
                            value={this.props.plan.year ? this.props.plan.year : ''}
                            onChange={e => {
                                if(!this.props.locked)
                                    this.props.setPlanValue(this.props.plan._id, 'year', e.target.value);
                            }}
                        />

                        <label>Description:</label>

                        <textarea
                            readOnly={this.props.locked}
                            style={this.getDescriptionStyle()}
                            value={this.props.plan.explanation}
                            onChange={e => {
                                if(!this.props.locked)
                                    this.props.setPlanValue(this.props.plan._id, 'explanation', e.target.value);
                            }}
                        />

                    </div>
                </div>

                {/* Replace with downloaded plan graphic */}
                {this.state.loadingGraphic
                    ? <div style={{ display: "inline-block", position: 'relative', left: 'calc(0%)' }}>{getLoadingGraphic('200px', '200px')}</div>
                    : this.state.graphic
                        ? <div className='planImg'>
                            <img
                                alt="plan"
                                style={{ display: "inline-block", maxWidth: "280px", cursor: "pointer" }}
                                src={this.state.graphic}
                                onClick={() => { window.open(this.state.graphic) }}
                            />
                        </div>
                        : <div style={{ display: "inline-block" }}>
                            <img
                                src="/icons/place-holder-plan.svg"
                                width="280px"
                                alt="No Graphic"
                                style={{objectFit: 'contain'}}
                            />
                            <div className="placeholder-plan-text">Please Upload Graphic</div>
                        </div>}

            </div>
        )
    }
}