// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import api from "../api";

export default class Login extends React.Component {

    state = {
        username: "",
        password: "",
        loginSuccess: true,
    }

    attemptLogin = async () => {

        await api.post("self/adminLogin", {adminUser: this.state.username, adminPassword: this.state.password})
        .then(() => this.props.history.push('/'))
        .catch(() => { this.setState({loginSuccess: false})})

    }

    render = () => {
        return (<div className="login-container">

            <img
                src="/logo/logo.png"
                alt="FBC"
                onClick={() => window.open("https://fbcserv.com", "_blank")}
            />

            <h1>Welcome!</h1>
            <h2>Log in to your account</h2>

            <label>Username</label>
            <input
                type="text"
                value={this.state.username}
                onChange={(e) => this.setState({ username: e.target.value, loginSuccess: true })}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.loginButton.click();
                }}
            />

            <label>Password</label>
            <input
                type="password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value, loginSuccess: true })}
                onKeyPress={e => {
                    if (e.key === 'Enter')
                        this.refs.loginButton.click();
                }}
            />

            {this.state.loginSuccess ? null :
                <div className="failed-login">
                    <span> &#9447; </span> Sorry, we couldn't find an account with that user name and password.
                </div>
            }

            <button
                ref='loginButton'
                onClick={() => this.attemptLogin()} onSubmit={() => this.attemptLogin()}
                style={{ width: '80%', marginLeft: '10%', marginTop: "20%", borderRadius: "4px", }}
            >Login</button>

        </div>)
    }

};