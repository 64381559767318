import React, { Component } from 'react';

import '../../styles/checkbox.css';

export default class PlanCard extends Component {

    state = {

    }

    render = () => {
        console.log(this.props)
        return (
            // <span className={(this.props.className ? this.props.className : '')}>
                <div
                    className={'checkboxContainer'}
                    // style={{ width: this.props.size ? this.props.size : '20px', height: this.props.size ? this.props.size : '20px'}}
                    onClick={() => this.refs.checkboxBox.click()}
                >
                    <input
                        ref='checkboxBox'
                        className='checkboxBox'
                        type='checkbox'
                        checked={this.props.checked}
                        onClick={e => this.props.onClick(e)}
                    />
                    <div className='checkboxMark'></div>
                </div>
            // </span>
        )
    }

}