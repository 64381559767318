// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import '../../styles/dashboard.css'

import Select from 'react-select';

import api from '../../api';

import ClaimTable from '../subComponents/ClaimTable'

import { fetchClaimsCount, fetchClaims } from '../../helpers/dataHandler';

export default class Dashboard extends React.Component {

    state = {
        notificationsOpened: true,
        claims: [],
        currentTimeScale: 3,
        notifications: [],
    }

    fetchInterval = null;

    componentDidMount = async () => {

        this.props.setLoadingState(true);

        await api.post("self/adminLogin").catch(() => {
            this.props.history.push("/login");
            return
        })

        await this.fetchClaims();
        let notifications = await api.get("notification/getByQuery", { query: { acknowledged: false } })
        this.setState({notifications});

        this.fetchInterval = setInterval(this.fetchClaims, this.props.refreshInterval ? this.props.refreshInterval : 60000);
        this.props.setLoadingState(false);
    }

    componentWillUnmount = async () => {
        if (this.fetchInterval)
            clearInterval(this.fetchInterval)
    }

    fetchClaims = async () => {
        let query = {};

        // get time offset date
        if (this.state.currentTimeScale > 0) {
            let dateAfter = new Date();
            dateAfter.setMonth(dateAfter.getMonth() - this.state.currentTimeScale);
            query = {createdDate: { $gte: dateAfter }}
        };

        console.log('Query:', query); // Log the query

        const claims = await fetchClaims(query);
        console.log('Fetched Claims:', claims); // Log the fetched claims

        await this.setState({claims: claims ? claims : []});
    }

    getPendingClaims = () => {
        return this.state.claims.filter(claim => claim.status === 'Pending')
    }

    getFlaggedClaims = () => {
        return this.state.claims.filter(claim => claim.status === 'Flagged')
    }

    setNewTimeScale = async newTimeScale => {
        this.props.setLoadingState(true);
        await this.setState({currentTimeScale: newTimeScale});
        await this.fetchClaims();
        this.props.setLoadingState(false);
    }

    render = () => {
        return (<>

            <h2>Welcome to the {this.props.whatever}</h2>
            <h1 style={{ marginTop: -15 }}>FBC Claims Manager Portal</h1>

            {/* carets are U+25b2 and U+25bc */}
            {/* <div className="notificationContainer">
                <h2
                    className="sub-header notificationTitle"
                    style={{width: '200px'}}
                    onClick={() => this.setState({ notificationsOpened: !this.state.notificationsOpened })}
                >
                    Notifications ({this.state.notifications.length})
                    <div style={{display: 'inline-block', marginLeft: '20px', fontSize: '16px'}} className='clickable'>
                        {this.state.notificationsOpened ? <img src="/icons/dropDown.svg" alt="drop down icon"/> : <img src="/icons/upDropDown.svg" alt="Up Icon "/>}
                    </div>
                </h2>

                {this.state.notificationsOpened && <div className="notificationList">
                    {this.state.notifications.map((notif, idx) => {
                        return (
                            <div className='notification' key={'notification_' + idx}>

                                <a href={notif.link}>
                                    <img src='/icons/notification2.svg' alt='notification'/>
                                </a>

                                <img
                                src = "/icons/acknowledge.svg"
                                alt = "confirm"
                                style = {{cursor: "pointer"}}
                                onClick={async () => {
                                    await api.post("notification/update", { id: notif._id, diff: { acknowledged : true }});
                                    let notifications = await api.get("notification/getByQuery", { query: { acknowledged: false } })
                                    this.setState({notifications})
                                }}/>

                                {notif.name}

                            </div>
                        )
                    })}
                </div>}
            </div> */}

            <div className="view-claims-link clickable" onClick={() => this.props.history.push('/claims')}>View All Claims</div>

            <ClaimTable
                title='Pending Claims'
                data={this.getPendingClaims()}
                operators={this.props.operators}
                history={this.props.history}
                employeeIdForClaims={this.props.employeeIdForClaims}
                reloadData={this.fetchClaims}
                reloadOperators={this.props.reloadOperators}
                currentTimeScale={this.state.currentTimeScale}
                setNewTimeScale={this.setNewTimeScale}
                setLoadingState={this.props.setLoadingState}
            />

            <ClaimTable
                title='Flagged Claims'
                data={this.getFlaggedClaims()}
                operators={this.props.operators}
                history={this.props.history}
                employeeIdForClaims={this.props.employeeIdForClaims}
                reloadData={this.fetchClaims}
                reloadOperators={this.props.reloadOperators}
                currentTimeScale={this.state.currentTimeScale}
                setNewTimeScale={this.setNewTimeScale}
                setLoadingState={this.props.setLoadingState}
            />

        </>)
    }
};